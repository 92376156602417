@font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto-Regular.ttf");
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/Poppins-Regular.ttf");
}

body {
    font-family: "Poppins", "Roboto", "Arial", sans-serif
}

.fixed-container {
    margin-top: 68px;
    /*min-height:840px;*/
    align-content: baseline;
    justify-content: center;
}

p {
    margin-top: 0rem;
    margin-bottom: 0rem;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.rdw-editor-wrapper {
    min-height: 350px
}

.DraftEditor-root {
    border: 1px solid #e2e2e2;
    border-radius: 5px;
    min-height: 335px;
    padding-left: 10px;
}

.customalabel > div > label {
    top: -7px;
    left: 0px;
    width: auto;
    position: relative;

}

.custom-datepicker {
    /*padding: 16.5px 14px;*/
    font: inherit;
    letter-spacing: inherit;
    /*border: 1px solid rgba(0, 0, 0, 0.23);*/
    border: 0;
    width: 95%;
    /*border-radius: 5px;*/
    margin-top: 4px;
    height: 41px;
    padding-left: 10px;
}

.custom-datepicker-2 {
    /*padding: 16.5px 14px;*/
    font: inherit;
    letter-spacing: inherit;
    /*border: 1px solid rgba(0, 0, 0, 0.23);*/
    border: 0;
    width: 100%;
    /*border-radius: 5px;*/
    margin-top: 4px;
    height: 41px;
    padding-left: 10px;
}

.custom-container-datepicker {
    display: inline-flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
    vertical-align: top;
    width: 100%;
}

.react-datepicker__input-container {
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 5px;
    margin-right: 32px;
    height: 49px;

}

.card-custom-datepicker {
    padding: 16.5px 14px;
    font: inherit;
    letter-spacing: inherit;
    border: 1px solid rgba(0, 0, 0, 0.23);
    width: 93%;
    border-radius: 5px;
    margin-top: -9px;
    height: 5px;

}

.card-custom-datepicker-mini {
    padding: 16.5px 14px;
    font: inherit;
    letter-spacing: inherit;
    border: 1px solid rgba(0, 0, 0, 0.23);
    width: 90%;
    border-radius: 5px;
    margin-top: -9px;
    height: 5px;
}

.custom-label {
    color: rgba(0, 0, 0, 0.6);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    padding: 0;
    position: relative;
    display: block;
    transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    left: 9px;
    top: -9px;
    z-index: 1;
    background: #fff;
    padding-left: 3px;
    padding-left: 3px;
}


.flip-countdown.theme-light .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__top, .flip-countdown.theme-light .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__bottom, .flip-countdown.theme-light .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__back::before, .flip-countdown.theme-light .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__back::after {
    color: #2c3d47 !important
}

.flip-countdown {
    position: relative;
    top: -37px;
    width: 485px;
    background: #fff;
    opacity: 1 !important;
    border-radius: 13px;

    padding-top: 15px;
    min-height: 40px;
    border: 1px solid #aab9c2;
}

.flip-date {
    position: relative;
    top: -37px;
    width: 485px;
    background: #fff;
    opacity: 1 !important;
    border-radius: 13px;
    font-size: 1.5rem;
    min-height: 85px;
    border: 1px solid #aab9c2;
    text-align: center;
    font-family: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif";
    display: flex;
    justify-content: center;
    align-items: center;
}


.flip-countdown-title {
    font-size: 13px !important;
    font-weight: bold;
}

.text-small {
    font-size: 10px
}

.sidebar {


    bottom: 0;
    left: 0;
    margin-top: 0;
    position: fixed;
    top: 60px;
    transition: all 0.2s ease-in-out 0s;
    width: 240px;
    z-index: 1001;
}

.primary-nav {
    position: fixed;
    z-index: 999;
    height: 100%;
}

.primary-nav .menu li {
    position: relative;
}

.primary-nav .menu li.active {
    position: relative;
}

/*mobile*/

@media all and (max-width: 400px) {

    .dropzone-container {
        width: 350px !important;
        padding: 0.5rem !important;
    }
}

@media all and (max-width: 799px) {

    .custom-datepicker {
        width: 92% !important;
    }

    .card-custom-datepicker {
        width: 90% !important;
    }

    .no-mobile {
        display: none !important;
    }

    .text-small {
        font-size: 9px !important
    }

    .flip-countdown.size-medium .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec {
        margin: 0 0.025em;
        font-size: 48px !important;
    }

    .flip-countdown {
        width: 95%;
        left: 1px !important;
        top: -14px !important;
    }

    .flip-date {
        width: 95%;
        left: 1px !important;
        top: -14px !important;
    }
}


@media all and (min-width: 1280px) {
    .MuiContainer-maxWidthLg {
        max-width: 1280px !important;
    }

    .col-lg-4-c {
        flex: 0 0 auto;
        width: 33.3333333333% !important;
        padding-right: 10px !important;
        padding-left: 46px !important;
    }
}

@media all and (min-width: 1536px) {
    .MuiContainer-maxWidthXl {
        max-width: initial !important;
    }
}

.term-sub {
    font-size: 16px
}

.term-title {
    font-size: 20px
}

.text-justify {
    text-align: justify
}

.container-terms p {
    text-align: justify
}

.font-face-UniversBold {
    font-family: "UniversBold";
}

.font-face-UniversCnBold {
    font-family: "UniversCnBold";
}

.font-face-UniversCnRg {
    font-family: "UniversCnRg";
}

.font-face-UniversLight {
    font-family: "UniversLight";
}

.font-face-UniversRegular {
    font-family: "UniversRegular";
}
