body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: 'GothamRoundedBold';
    src: local('GothamRoundedBold'), url("./assets/fonts/unicef/GothamRoundedBold.ttf") format('ttf');
}

@font-face {
    font-family: 'gothicb';
    src: local('gothicb'), url("./assets/fonts/unicef/gothicb.ttf") format('ttf');
}

@font-face {
    font-family: 'MyriadPro-Regular';
    src: local('MyriadPro-Regular'), url(./assets/fonts/unicef/MyriadPro-Regular.otf) format('otf');
}

@font-face {
    font-family: 'Spartan-Bold';
    src: local('Spartan-Bold'), url(./assets/fonts/unicef/Spartan-Bold.ttf) format('ttf');
}

@font-face {
    font-family: 'Spartan-SemiBold';
    src: local('Spartan-SemiBold'), url(./assets/fonts/unicef/Spartan-SemiBold.ttf) format('ttf');
}

@font-face {
    font-family: "UniversBold";
    src: url("./assets/fonts/univers/UniversBold.ttf") format("truetype");
}

@font-face {
    font-family: "UniversCnBold";
    src: url("./assets/fonts/univers/UniversCnBold.ttf") format("truetype");
}

@font-face {
    font-family: "UniversCnRg";
    src: url("./assets/fonts/univers/UniversCnRg.ttf") format("truetype");
}

@font-face {
    font-family: "UniversLight";
    src: url("./assets/fonts/univers/UniversLight.ttf") format("truetype");
}

@font-face {
    font-family: "UniversRegular";
    src: url("./assets/fonts/univers/UniversRegular.ttf") format("truetype");
}
