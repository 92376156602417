.mobilenav__btn {
    display: none;
    font-size: 32px;
    margin-left: 6px;
}

.navbar__logo {
    display: flex;
    justify-content: flex-start;
    padding-left: 0rem !important;
}

.navbar__links {
    display: flex;
    justify-content: flex-end;

}

@media (max-width: 799px) {
    .navbar__links {
        display: none;
    }

    .navbar__logo {
        display: flex !important;
        justify-content: center !important;
        padding-left: 0rem !important;

    }

    .mobilenav__btn {
        display: block;
    }
}

@media (max-width: 290px) {
    .navbar__logo {
        margin-right: -8px !important;
    }
}

