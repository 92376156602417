.form-panel {
    transition: all 0.5s ease-out;
    opacity: 0;
    max-width: 420px;
    position: absolute;
    top: calc(15%);
    background: #fff;
    transform: scaleX(1);
    transform-origin: bottom right;

}

@media all and (max-width: 1240px) {

    .form-panel {
        top: calc(1%) !important
    }
}

@media all and (max-width: 1200px) {

    .form-panel {
        top: calc(10%) !important
    }
}

@media all and (max-width: 900px) {

    .form-panel {
        top: calc(1%) !important
    }
}

@media all and (max-width: 600px) {

    .form-panel {
        top: 0px !important
    }
}
