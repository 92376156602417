/* BasesDelSorteo.css */

#bases h1 {
    text-align: center;
    font-size: 2em !important;
    margin-bottom: 1em;
    color: rgb(0,176,240) !important;;
}

#bases section {
    margin: 1em 0 !important;
    padding: 1em;
}

#bases a {
    color: rgb(0,176,240) !important;;
}

#bases h2 {
    font-size: 1.5em !important;
    color: #333;
    margin-bottom: 0.5em;
    color: rgb(0,176,240) !important;
}

#bases p {
    font-size: 1em !important;
    color: #666;
    line-height: 1.6 !important;
}

#bases ul {
    margin-left: 20px;
    list-style-type: disc;
}

#bases ul li {
    margin-bottom: 0.5em;
}

#bases strong {
    font-weight: bold;
    color: #000;
}
